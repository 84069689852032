import { Row, Col, Input, Button, Form, message } from "antd";
import { useState } from "react";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { http } from "../../helpers/http";
import { LanguageSwitch } from "../components/LanguageSwitch";

export const RenewPasswordForm = () => {
    const { t } = useTranslation();
    const [canSend, setCanSend] = useState(false);
    const [secret, setSecret] = useState(null);
    const [form] = Form.useForm();
    const captchaAPI = process.env.REACT_APP_RECAPTCHA ? process.env.REACT_APP_RECAPTCHA : ""

    const submitHandler = () => {
        form.validateFields().then(async (values: any) => {
            await http.post(`/reset-password`, { email: values.email, secret: secret }).then((response: any) => {
                if (response.data.status === "error") {
                    message.error(t("errors.error"))
                } else {
                    message.success(t("login.new_password.password_was_sent_to_email"))
                }
            }).catch(() => {
                message.error(t("errors.error"))
            })
        });
    }

    const captchaHandler = (e: any) => {
        setSecret(e)
        if (e !== null) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }
    }

    return (
        <div className="login-card">
            <Link style={{ position: "absolute", top: 10, left: 10, fontSize: 12 }} to="/">{t("common.beginning")}</Link>
            <Row justify={"space-between"} style={{ marginBottom: 28, flexWrap: "nowrap" }}>
                <h2 style={{ margin: 0 }}>{t("login.new_password.renew_password")}</h2>
                <LanguageSwitch />
            </Row>
            {
                captchaAPI
                && <Form form={form} name="login_form" onFinish={submitHandler} layout="vertical">
                    <Form.Item
                        name="email"
                        label={t("login.e_mail")}
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: t("login.new_password.renew_password") as string,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <ReCAPTCHA
                        sitekey={captchaAPI}
                        onChange={captchaHandler}
                    />
                    <Button disabled={!canSend} style={{ marginTop: 15, width: "100%" }} htmlType='submit' type="primary">{t("login.new_password.confirm")}</Button>
                </Form>
            }
        </div>
    )
}