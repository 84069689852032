import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { ReactComponent as ReadMore } from '../../img/ic-right-arrow-circle.svg';
import i18n from 'i18next';
import { LessonPlayer } from "./video/LessonPlayer";

export const Instructions = (props: any) => {
    const { t } = useTranslation();
    const videoURL = process.env.REACT_APP_API_URL + `school-student-api/get-instruction-video?e_instruction_id=1&language_id=${1}&video_quality=medium&bearrer_token=&verified`;

    const [instructions, setInstructions] = useState({} as any);
    const [token, setToken] = useState("" as any);
    const [verification, setVerification] = useState("" as any);
    const [url, setUrl] = useState(videoURL as any);
    const [expanded, setExpanded] = useState(false);
    const [languages, setLanguages] = useState([] as any);
    const [qualities, setQualities] = useState([] as any);
    const [mobile, setMobile] = useState(false);

    const fetchToken = () => {
        const tokenData = http.get(`/get-instruction-token`).then((response: any) => {
            return http.post('/token/verify', response.data.token.token).then((resp: any) => {
                setToken(response.data.token.token);
                setVerification(resp.data.verified);
                setTimeout(() => setVerification(""), 18000000);
                return {
                    token: response.data.token.token,
                    verification: resp.data.verified
                }
            })
        })
        return tokenData;
    };

    const fetchLanguages = () => {
        http.get('languages').then((res: any) => {
            setLanguages(res.data.data);
        })
    }

    const fetchInstruction = async () => {
        await http.get(`/get-video-instruction`).then((response: any) => {
            setInstructions(response.data);
        })
    };

    const fetchQualities = () => {
        if (languages?.length === 0 || qualities?.length > 0) return;
        const language = languages.find((el: any) => {
            return el.iso === i18n.language
        }) as any;
        http.get(`get-instruction-quality`, {
            params: {
                e_instruction_id: 1,
                language_id: language.id
            }
        }).then((res: any) => {
            setQualities(res.data.qualities)
        }).catch((err: any) => {
            //console.log("No video")
        })
    }

    const changeUrlParam = async (params: any) => {
        let newUrl = url;
        let newVerification;
        let parameters = params;
        if (verification.length === 0) {
            await fetchToken().then((data: any) => {
                newVerification = {
                    bearrer_token: data.token,
                    verified: data.verification
                }
                parameters = {
                    ...parameters,
                    ...newVerification
                }
            })
        }
        console.log(parameters)
        Object.keys(parameters).forEach((key: any) => {
            newUrl = newUrl.split("&").map((el: any) => {
                if (el.includes(key)) {
                    return `${key}=${parameters[key]}`
                }
                return el
            }).join("&");
        });
        setUrl(newUrl);
    };

    useEffect(() => {
        console.log(url)
    }, [url])

    useEffect(() => {
        setMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setMobile(window.matchMedia("(max-width: 767px)").matches)
        })
        fetchInstruction();
        fetchLanguages();
    }, []);

    useEffect(() => {
        if (languages.length === 0) return;
        fetchQualities();
        const lang = languages.find((el: any) => {
            return el.iso === i18n.language;
        }) as any;
        changeUrlParam({ language_id: lang.id })
    }, [languages, i18n.language])

    // useEffect(() => {
    //     if (verification.length === 0) return;
    //     if (token !== null || token.length > 0) {
    //         setUrl(videoURL + token + `&verified=${verification}`);
    //     }
    // }, [verification]);

    useEffect(() => {
        //console.log(url)
    }, [url])

    return <Card className="e-learning-card">
        <Row gutter={mobile ? 0 : 16}>
            <Col xl={12} style={{ paddingRight: mobile ? 0 : 22 }}>
                <div className="instructions-player-container">
                    {/* <video
                        src={url}
                        controls
                        width="100%"
                        controlsList="nodownload"
                    // height="100%"
                    /> */}
                    <LessonPlayer
                        url={url}
                        changeQuality={(quality: any) => changeUrlParam({ video_quality: quality })}
                        qualities={qualities}
                    />
                </div>
            </Col>
            <Col xl={12} style={{ paddingLeft: mobile ? 0 : 22, paddingRight: mobile ? 0 : 20 }}>
                <div className="instructions-container">
                    <div>
                        <h2>{t('e_learning.what_is_e_learning')}</h2>
                        <div dangerouslySetInnerHTML={{
                            __html: expanded
                                ? instructions[`full_text_${i18n.language}`]?.replaceAll("\n", "<br />")
                                : instructions[`small_text_${i18n.language}`]?.replaceAll("\n", "<br />")
                        }} />
                    </div>
                    <Button
                        type="text"
                        onClick={() => setExpanded(!expanded)}
                        className="read-more"
                    >
                        <b>
                            {
                                expanded
                                    ? t('e_learning.less')
                                    : t('e_learning.read_more')
                            }
                        </b>
                        <ReadMore style={{ marginLeft: 10, width: 20, height: 20 }} />
                    </Button>
                </div>
            </Col>
        </Row>
    </Card>
}