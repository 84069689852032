import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";


export const CountDownTimer = (props: any) => {
    const { seconds, stopTimer, setTimeIsUp, start_time, returnTime, debugTimeNow } = props;
    const [timer, setTimer] = useState(0);
    // const [startTime, setStartTime] = useState(moment(start_time))
    const [endTime, setEndTime] = useState(moment(start_time, "YYYY-MM-DD HH:mm:ss").add(seconds, 'seconds').format("YYYY-MM-DD HH:mm:ss"));

    const setExpired = () => {
        setTimer(0);
        return ""
    }

    useEffect(() => {
        setTimer(seconds)
    }, [])

    useEffect(() => {
        const clock = setInterval(() => {
            //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
            console.log(Math.abs(moment().diff(moment(endTime, "YYYY-MM-DD HH:mm:ss"), "seconds")))
            if (Math.abs(moment().diff(moment(endTime, "YYYY-MM-DD HH:mm:ss"), "seconds")) > seconds) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                setTimeIsUp(true);
                return;
            }
            if (stopTimer) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                return;
            };
            if (moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment()) < 0) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                setTimeIsUp(true);
                return;
            }

            setTimer(prev => prev - 1)
            returnTime(moment(moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment())).format("mm:ss"));
        }, 1000);
        return () => { clearInterval(clock) };
    });

    useEffect(() => {
        console.log(endTime, start_time)
    }, [endTime])

    return <b style={{ color: "#CC3326" }}>
        {
            moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment()) <= 0
                ? "00:00"
                : moment(moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment())).format("mm:ss")
        }
    </b>
}