import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from "axios";
import { Button, Card, Layout, Row } from "antd";
import moment from "moment";
import { ReactComponent as GoBackArrow } from '../../img/ic-goback.svg';
import { useNavigate } from "react-router-dom";

export const MyQuestions = () => {
    const { t } = useTranslation();
    const [questions, setQuestions] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getMyOnlineQuestions = () => {
        setLoading(true);
        http.get(`/my-elearning-questions`).then((response: AxiosResponse) => {
            setQuestions(response.data);
            setLoading(false);
        }).catch((error: AxiosError) => {
            console.log('my-elearning-questions-err', error);
            setQuestions([]);
            setLoading(false);
        });
    };

    useEffect(() => {
        getMyOnlineQuestions();
    }, [])

    const renderQuestion = (question: any) => {
        return <div
            key={question.created_at}
            style={{
                padding: 20,
                marginBottom: 20,
                border: "1px solid #f0f0f0",
                borderRadius: 5,
                backgroundColor: "#f9f9f9"
            }}>
            <Row justify="space-between">
                <p>
                    {moment(question.created_at, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss")}
                </p>
                <p>
                    {question.module_ind}. {t('e_learning.lesson')}, {question.chapter_ind}. {t('e_learning.chapter')}
                </p>
            </Row>
            <div>
                <p>{question.question}</p>
            </div>
            {
                question.answer
                && <div style={{ marginTop: 10, border: "1px solid green", borderRadius: 5, padding: 10 }}>
                    <p style={{ color: "green" }}>
                        {t('e_learning.answer')}: {question.answer}
                    </p>
                </div>
            }
        </div>
    }

    return <Layout className="main-content">
        <Row className="e-learning-header">
            <Button
                className="go-back-btn"
                style={{ fontWeight: "bold", marginRight: 29 }}
                icon={<GoBackArrow />}
                onClick={() => navigate(-1)}
            >
            </Button>
            <b style={{ lineHeight: "30px" }}>{t('navigation.asked_questions')}</b>
        </Row>
        <Card loading={loading}>
            {
                questions.length > 0
                    ? questions?.map((question: any, index: number) => {
                        return renderQuestion(question)
                    })
                    : <div
                        style={{
                            padding: 20,
                            border: "1px solid #f0f0f0",
                            borderRadius: 5,
                            backgroundColor: "#f9f9f9"
                        }}
                    >
                        <p><b>{t("e_learning.no_questions_sent")}</b></p>
                    </div>
            }
        </Card>
    </Layout>
}