import { Card, Layout, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import i18n from 'i18next';
import moment from "moment";

const centered = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
} as any;

export const ExamStartPage = (props: any) => {
    const { t } = useTranslation();
    const { user } = props;
    const navigate = useNavigate();
    const { mid } = useParams() as any;
    const [examPassed, setExamPassed] = useState(false);
    const [module, setModule] = useState(undefined as any);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(false);

    const startExam = async () => {
        setLoading(true);
        await http.post(`/start-module-exam/${mid}`, { override: 0, started_date: moment().format("YYYY-MM-DD HH:mm:ss") }).then((response: any) => {
            //console.log(response)
            if (response?.data?.data === null) {
                message.error("Nav pabeigtas visas moduļa nodaļas!")
                setLoading(false);
                setTimeout(() => navigate(-1), 2000)
                return;
            }
            if (response?.data?.data?.exam?.passed) {
                setExamPassed(true);
                return;
            }
            navigate('/exam-instance', { state: { data: response?.data?.data, module: module } });
            setLoading(false);
        });
    };

    const fetchData = async () => {
        await http.get(`learning-modules-list/${user?.category?.id}/${mid}`).then((response: any) => {
            setModule(response?.data?.data);
            //console.log(response)
            if (response.data?.data?.passed) {
                setExamPassed(true)
                http.post(`/set-passed-module/${module?.hash_id}`, { skip: 1 });
            }
        });
    };

    useEffect(() => {
        fetchData();
        setMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setMobile(window.matchMedia("(max-width: 767px)").matches)
        })
    }, [user]);

    return <Layout className="main-content">
        <Card className="module-exam-card">
            <h2>{module?.ind + 1 || false}. {t('e_learning.lesson')}. {t('e_learning.final_test')}.</h2>
            <p>{t('e_learning.keywords')}: {module?.[`description_${i18n?.language}`]}</p>
            <div
                className="exam-status-container"
            >
                {
                    examPassed && <h2 style={{ display: mobile ? "block" : "none" }}>{t('e_learning.exam_is_passed')}</h2>
                }

                <Row>
                    <div className="status-wrap">
                        <p>{t('e_learning.status')}</p>
                        <b className={`${module?.passed ? "completed" : "not-completed"}`}>
                            {module?.passed ? t('e_learning.passed') : t('e_learning.failed')}
                        </b>
                    </div>
                    <div className="status-wrap">
                        <p>{t('e_learning.tries_left')}</p>
                        <b>{module?.attempts_left} {module?.attempts_left === 1 ? t('e_learning.try') : t('e_learning.tries')}</b>
                    </div>
                    <div className="status-wrap">
                        <p>{t('e_learning.questions_count')}</p>
                        <b>{module?.exam_questions_count} {t('e_learning.questions')}</b>
                    </div>
                    <div className="status-wrap">
                        <p>{t('e_learning.time_limit')}</p>
                        <b>{module?.exam_time_limit} {t('common.minutes')}</b>
                    </div>
                    <div className="status-wrap">
                        <p>{t('e_learning.permited_wrong_answers')}</p>
                        <b>{module?.exam_max_errors} {module?.exam_max_errors === 1 ? t('errors.error') : t('errors.errors')}</b>
                    </div>
                    <div className="status-wrap"></div>
                </Row>
                {
                    examPassed
                        ? <h2 style={mobile ? { textAlign: "center", display: "none" } : centered}>{t('e_learning.exam_is_passed')}</h2>
                        : module?.attempts_left === 0
                            ? <h2 style={centered}>{t('e_learning.tries_count_is')} 0</h2>
                            : <button
                                className={`btn-start-test ${module === undefined ? 'disabled' : ''}`}
                                onClick={startExam}
                                disabled={examPassed || module === undefined || loading}
                            >
                                {t('e_learning.start_test')}
                            </button>
                }
            </div>
        </Card>
    </Layout>
}