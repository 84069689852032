import { Button, Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export const LessonRestriction = (props: any) => {
    const { status } = props;
    const navigate = useNavigate();

    return <Card>
        <h1>{status}</h1>
        <Button type="primary" ghost onClick={() => navigate(-1)}>Atgriezties</Button>
    </Card>
}