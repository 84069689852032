import { Button } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IcPin } from '../../img/ic-pin.svg';
import { ReactComponent as IcCheckSmall } from '../../img/ic-small-check-xs.svg';
import { http } from "../../helpers/http";
import moment from "moment";

export const ModuleMiniCard = (props: any) => {
    const { t, i18n } = useTranslation();
    const { ind, module, userID } = props;

    const resetModuleProgress = () => {
        http.post(`reset-module/${module.hash_id}`).then(() => {
            window.location.href = "/e-learning"
        })
    }

    useEffect(() => {
        console.log(module, module.failed_exam)
    }, [])

    return <>
        <h3 style={{ color: props.done ? "#2C9C3B" : module.failed_exam ? "red" : "#000000", display: "flex", alignContent: "center" }}>
            <b>{ind + 1}. {t('e_learning.lesson')} {module.failed_exam ? "(Nav nokārtots)" : ""}</b>
            {
                props.done
                    ? <IcCheckSmall style={{ marginLeft: 10, width: 18, height: 18 }} />
                    : <></>
            }
        </h3>
        {
            (props.active
                && module.available_date)
            && <b>Pieejams: {moment(module.available_date).format("DD.MM.YYYY")}</b>
        }
        {module.failed_exam ? <Button onClick={resetModuleProgress}>{t("e_learning.start_from_begining")}</Button> : <></>}
        <p>{module[`description_${i18n.language}`]}</p>
        {/* {
            props.active
                ? <Button danger type="text" style={{ display: "flex" }}>
                    <IcPin style={{ marginRight: 10 }} />
                    Nodarbības prezentācijas materiāli
                </Button>
                : <></>
        } */}

    </>
}