import { Modal, Row, Col, Input, Checkbox, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as IconAskNoBg } from "../../../img/ic-ask-question-no-bg.svg"
import { useTranslation } from "react-i18next";
import { http } from "../../../helpers/http";

export const AskLecturerModal = (props: any) => {
    const {
        askLecturerVisible,
        setAskLecturerVisible,
        user,
        videoTime,
        chapterID,
        language
    } = props;
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        student_email: user.email,
        question: '',
        timer: videoTime
    });
    const [error, setError] = useState({
        question: false,
        email: false
    });
    const [loading, setLoading] = useState(false);

    const removeError = (key: any) => {
        setError((prev: any) => {
            return {
                ...prev,
                [key]: false
            }
        })
    };

    const validateFields = (data: any) => {
        setError({
            question: data.question.length === 0,
            email: data.student_email.length === 0
        });
        return data.question.length > 0 && data.student_email.length > 0;
    }

    const sendMessage = () => {
        setLoading(true);
        if (!validateFields(formData)) {
            setLoading(false)
            return;
        }
        http.post(`create-elearning-question/${chapterID}`, {
            // student_email: formData.student_email,
            language,
            question: formData.question,
            timer: videoTime
        }).then((response: any) => {
            message.success(t('e_learning.question_sent'))
            resetModal();
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!")
            setLoading(false);
        })
    }

    const inputHandler = (key: any, value: any) => {
        setFormData((prev: any) => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    const resetModal = () => {
        setFormData((prev: any) => {
            return {
                ...prev,
                question: ''
            }
        })
        setAskLecturerVisible(!askLecturerVisible);
    }

    useEffect(() => {
        console.log(chapterID)
    }, [])

    return <Modal
        className="ask-lecturer-modal"
        bodyStyle={{
            padding: "48px 48px 0"
        }}
        open={askLecturerVisible}
        onCancel={() => resetModal()}
        footer={(
            <Row
                style={{ padding: "0 32px 38px" }}
            >
                <Button
                    key="submit"
                    className="btn-blue"
                    style={{ width: "100%", height: "unset" }}
                    onClick={() => sendMessage()}
                    loading={loading}
                >
                    {t('e_learning.ask_questions')}
                </Button>
            </Row>
        )}
    >
        <Row>
            <Col span={24} style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                <div style={{
                    width: 48,
                    height: 48,
                    backgroundColor: "#059DC1",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <IconAskNoBg />
                </div>
            </Col>
            <Col span={24}>
                <h2 style={{ textAlign: "center" }}>{t('e_learning.ask_lecturer')}</h2>
                {/* <p style={{ textAlign: "center", marginBottom: 20 }}>{t('e_learning.answer_you_will_get_to_email')}.</p> */}
                {/* {
                    error.email
                        ? <span style={{ color: "red" }}>Šis lauks ir obligāts</span>
                        : <></>
                } */}
                {/* <Input
                    type="email"
                    style={{ marginBottom: 10, borderColor: error.email ? "red" : "#d9d9d9" }}
                    defaultValue={user.email}
                    value={formData.student_email}
                    name="student_email"
                    onChange={(e: any) => {
                        removeError(e.target.name);
                        inputHandler(e.target.name, e.target.value);
                    }}
                /> */}
                {
                    error.question
                        ? <span style={{ color: "red" }}>Šis lauks ir obligāts</span>
                        : <></>
                }
                <Input.TextArea
                    style={{ marginBottom: 20, resize: "none", borderColor: error.question ? "red" : "#d9d9d9" }}
                    rows={4}
                    value={formData.question}
                    name="question"
                    onChange={(e: any) => {
                        removeError(e.target.name);
                        inputHandler(e.target.name, e.target.value);
                    }}
                />
                <Row justify="space-between" align="middle">
                    <div>
                        {/* <Checkbox
                            style={{ marginRight: 7 }}
                        /> */}
                        <b>{t('e_learning.video_time')}:</b>
                    </div>
                    <Input
                        value={videoTime}
                        style={{ width: 100, padding: "10px 20px" }}
                    />
                </Row>
            </Col>
        </Row>
    </Modal>
}