import './App.scss';
import { Button, Layout, Menu, MenuProps, message } from 'antd';
import icSteeringWheel from '../src/img/ic-steering-wheel.svg';
import dashboardIcon from '../src/img/ic-sidebar-dashboard.svg';
import dashboardIconActive from '../src/img/ic-sidebar-dashboard-active.svg';
import theoryIcon from '../src/img/ic-sidebar-theory.svg';
import theoryIconActive from '../src/img/ic-sidebar-theory-active.svg';
import { Link, redirect, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Header } from './pages/components/Header';
import { DashboardLayout } from './pages/Dashboard/DashboardLayout';
import { LoginLayout } from './pages/Login/LoginLayout';
import { http } from './helpers/http';
import { AxiosResponse } from 'axios';
import { SettingsLayout } from './pages/Settings/SettingsLayout';
import { ELearningLayout } from './pages/e-learning/ELearningLayout';
import { ExamPage } from './pages/e-learning/ExamPage';
import { LanguageSwitch } from './pages/components/LanguageSwitch';
import { useTranslation } from 'react-i18next';

export type User = {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    category: Category;
    language: Language;
    category_id: number;
} | null;

type UserCategory = {
    category_id: number;
    category: string;
}

type School = {
    name: string;
} | null;

export type Category = {
    id?: number;
    value?: string;
}

export type Language = {
    id?: number;
    iso?: string;
}

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const [selectedKey, setSelectedKey] = useState(0);
    const [logedIn, setLogedIn] = useState<boolean | undefined>(undefined);
    const [bearerSet, setBearerSet] = useState<boolean | undefined>(false);
    const [user, setUser] = useState<User>(null);
    const [school, setSchool] = useState<School>(null);
    const [categories, setCategories] = useState<Category[] | []>([]);
    const [languages, setLanguages] = useState<Language[] | []>([]);
    const [showSider, setShowSider] = useState(true);
    const [userCategories, setUserCategories] = useState<UserCategory[] | []>([]);

    const menuLinksPremade = [
        // { icon: dashboardIcon, iconSelected: dashboardIconActive, to: "/", title: t("common.beginning") },
        { icon: theoryIcon, iconSelected: theoryIconActive, to: "/e-learning", title: t("navigation.e_learning") },
    ];

    const logout = () => {
        sessionStorage.clear();
        setBearerSet(false);
        window.location.href = "/"
    };

    const getSelfInfo = () => {
        http.get('self').then((res: AxiosResponse) => {
            console.log(res)
            const { data } = res.data;
            setUser({
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                category: data.category,
                language: {
                    id: data.language.id,
                    iso: data.language.iso
                },
                category_id: data.category_id
            })
            setUserCategories(
                data.categories.map((category: UserCategory): UserCategory => {
                    console.log(category)
                    return {
                        category_id: category.category_id,
                        category: category.category
                    }
                })
            )
            i18n.changeLanguage(data.language.iso)
            setSchool({
                name: data.school_data.school.name
            })
        }).catch((err: any) => {
            message.error(t("errors.error_loading_data"))
        })
    };

    const getClassifiers = async () => {
        await http.get('categories').then((res: AxiosResponse): void => {
            const { data } = res.data;
            if (data.length === 0) return;
            setCategories(data.map((item: Category): Category => {
                return {
                    id: item.id,
                    value: item.value
                }
            }))
        });
        await http.get('languages').then((res: AxiosResponse): void => {
            const { data } = res.data;
            if (data.length === 0) return;
            setLanguages(data.map((item: Language): Language => {
                return {
                    id: item.id,
                    iso: item.iso
                }
            }))
        });
    };

    const setActiveCategory = (category_id: number): void => {
        http.post(`switch-student-category/${category_id}`).then((res: AxiosResponse) => {
            window.location.href = "/";
            ;
        })
    }

    useEffect(() => {
        const sp = new URLSearchParams(window.location.search);
        const savedToken = sp.get("bt");
        if (savedToken != null) {
            sessionStorage.setItem("bearer_token", savedToken);
            window.location.href = "/";
            return;
        }
        const hasToken = sessionStorage.getItem("bearer_token");
        if (hasToken) {
            const token = sessionStorage.getItem("bearer_token");
            http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            console.log(http.defaults.headers.common["Authorization"])
            setLogedIn(true)
        }
        else setLogedIn(false);
    }, [bearerSet]);

    useEffect(() => {
        if (logedIn) {
            getSelfInfo();
            getClassifiers();
        }
    }, [logedIn]);

    useEffect(() => {
        console.log(location)
        if (location.pathname === "/" && logedIn) {
            navigate("/e-learning")
        }
    }, [location, logedIn])

    useEffect(() => {
        setSelectedKey(() => {
            return menuLinksPremade.findIndex((el: typeof menuLinksPremade[0]) => {
                return el.to.split("/")[1] === location.pathname.split("/")[1]
            })
        })
    }, [location])

    return logedIn === undefined
        ? <></>
        : !logedIn
            ? <LoginLayout setLogedIn={(state: boolean) => setBearerSet(state)} />
            : <Layout
                style={{ minHeight: "100vh" }}
            >
                {
                    showSider
                    && <Layout.Sider
                        breakpoint={'lg'}
                        collapsedWidth={0}
                        style={{ position: "fixed", zIndex: 100, height: "100vh" }}
                        width={240}
                    >
                        <div>
                            <div className="logo">
                                <div className='logo-img-wrapper'>
                                    <img src={icSteeringWheel} />
                                </div>
                                {school?.name}
                            </div>
                            <Menu
                                theme='dark'
                                selectedKeys={[String(selectedKey)]}
                                onSelect={(e) => setSelectedKey(Number(e.key))}
                                items={
                                    menuLinksPremade?.map((link: any, index: any) => {
                                        const active = link.to.split("/")[1] === location.pathname.split("/")[1]
                                        return {
                                            label: <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img style={{ marginRight: 12 }} src={active ? link.iconSelected : link.icon} />
                                                <Link to={link.to}>{link.title}</Link>
                                            </div>,
                                            key: index
                                        }
                                    }) as MenuProps['items']
                                }
                            />
                        </div>
                        <div
                            className='bottom'
                            style={{
                                padding: "0 20px 30px",
                            }}
                        >
                            <LanguageSwitch withReset />
                            <div style={{ marginBottom: 10, marginTop: 10 }}>
                                {
                                    userCategories?.map((stCat: UserCategory) => {
                                        const active = stCat.category_id === user?.category_id
                                        const category = userCategories?.find((el: UserCategory) => el.category_id === stCat.category_id)
                                        return <Button
                                            className='btn ghost'
                                            style={{
                                                display: "inline-block",
                                                margin: "0 5px 5px",
                                                backgroundColor: active ? "#079dc1" : "unset"
                                            }}
                                            onClick={() => setActiveCategory(stCat.category_id)}
                                        >
                                            {category?.category}
                                        </Button>
                                    })
                                }
                            </div>
                            <a
                                className='btn ghost'
                                style={{ marginBottom: 10, marginTop: 10 }}
                                href={`${process.env.REACT_APP_API_URL}storage/student-portal-help/eDrive_help.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('common.help')}
                            </a>
                            <p style={{ color: "#FBFBFC", marginBottom: 2, fontWeight: "bold" }}>{user?.first_name} {user?.last_name}</p>
                            <Button
                                type="text"
                                style={{
                                    color: "#C5C7CA",
                                    fontSize: 12,
                                    lineHeight: "18px",
                                    textAlign: "left",
                                    width: "auto",
                                    padding: 0,
                                    marginBottom: 30
                                }}
                                onClick={logout}
                            >
                                {t("common.logout")}
                            </Button>
                            {/* <p style={{ color: "#FBFBFC", margin: 0, fontSize: 12 }}>Powered by E-apmācība.lv</p> */}
                        </div>

                    </Layout.Sider>
                }
                <Layout className={`main-section ${!showSider ? "no-sider" : ""}`}>
                    <Routes>
                        <Route
                            path="/"
                        // element={<DashboardLayout title={menuLinksPremade[selectedKey]?.title} user={user} />}
                        />
                        <Route
                            path="/e-learning/*"
                            element={
                                <ELearningLayout
                                    title={menuLinksPremade[selectedKey]?.title}
                                    user={user}
                                />
                            }
                        />
                        <Route
                            path="/exam-instance"
                            element={<ExamPage isOpen={(value: boolean) => setShowSider(!value)} user={user} />}
                        />
                    </Routes>
                </Layout>
            </Layout >
}

export default App;
