import { Button, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GoBackArrow } from '../../../img/ic-goback.svg';

export const ElearningHeader = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Row className="e-learning-header" justify={"space-between"}>
            {/* <Button
                className="go-back-btn"
                style={{ fontWeight: "bold", marginRight: 29 }}
                icon={<GoBackArrow />}
                onClick={() => navigate(-1)}
            >
            </Button> */}
            <b style={{ lineHeight: "30px" }}>{t('navigation.e_learning')}</b>

            <Button
                onClick={() => navigate('/e-learning/questions')}
            >
                {t('navigation.asked_questions')}
            </Button>
        </Row>
    )
}