import { Row, Col, Input, Button, Form, message } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { LanguageSwitch } from "../components/LanguageSwitch";

export const NewPasswordForm = () => {
    const { t } = useTranslation();
    const params = useParams() as any;
    const navigate = useNavigate();

    const submitForm = async (values: any) => {
        if (values.password !== values.verify_password) {
            message.error(t("login.new_password.passwords_doesnt_match"))
        } else {
            http.post(`/change-password/${params.hash}`, {
                password: values.password,
                password_confirmation: values.verify_password
            }).then((response: any) => {
                message.success(t("login.new_password.password_changed_successfuly"))
                setTimeout(() => navigate('/'), 5000)
            }).catch(() => {
                message.error(t("errors.error"))
            })
        }
    }

    return <div className="login-card">
        <Link style={{ position: "absolute", top: 10, left: 10, fontSize: 12 }} to="/">{t("login.agreement.return")}</Link>
        <Row justify={"space-between"} style={{ marginBottom: 28 }}>
            <h2 style={{ margin: 0 }}>{t("login.new_password.create_new_password")}</h2>
            <LanguageSwitch />
        </Row>
        <Form name="login_form" onFinish={submitForm} layout="vertical">
            <Form.Item name="password" label={t("login.new_password.new_password")}>
                <Input.Password />
            </Form.Item>
            <Form.Item name="verify_password" label={t("login.new_password.repeat_password")}>
                <Input.Password />
            </Form.Item>
            <small>{t("login.new_password.min_password_length")}</small><br />
            <Button style={{ marginTop: 10, width: "100%", height: 32 }} htmlType='submit' type="primary">{t("login.new_password.confirm")}</Button>
        </Form>
    </div>
}