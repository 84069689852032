import { Button, Card, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { BookmarkTitleCard } from "./BookmarkTitleCard";
import { ReactComponent as IcBookmark } from '../../img/ic-cc-1.svg';
import { Instructions } from "./Instructions";
import { ModulesList } from "./ModulesList";
import { Route, Routes, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import { ProgressCard } from "./components/ProgressCard";
import moment from "moment";
import { ElearningHeader } from "./components/ElearningHeader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./custom-player.scss";
import "./e-learning.scss";
import "./exam.scss";
import "./question.scss";
import { LessonLayout } from "./LessonLayout";
import { ExamStartPage } from "./ExamStartPage";
import { BookmarksView } from "./BookmarksView";
import { MyQuestions } from "./MyQuestions";


export const ELearningLayout = (props: any) => {
    const { t } = useTranslation();
    const { user } = props;

    const navigate = useNavigate();

    const [bookmarks, setBookmarks] = useState(undefined as any);
    const [studentModules, setStudentModules] = useState([] as any);
    const [passedModules, setPassedModules] = useState([] as any);
    // const [activeModule, setActiveModule] = useState({} as any);
    const [modules, setModules] = useState([] as any);
    const [theoryLessons, setTheoryLessons] = useState();
    const [foreignLessons, setForeignLessons] = useState();
    const [allSignedLessons, setAllSignedLessons] = useState();
    const [allTheoryLessons, setAlltheoryLessons] = useState([] as any);
    const [notifications, setNotifications] = useState([] as any);
    const [customNotifications, setCustomNotifications] = useState([] as any);
    const [showInstructions, setShowInstructions] = useState(false);

    const fetchBookmarks = async () => {
        await http.get(`/bookmarks/${user.id}`).then((response: any) => {
            const bookmarks = Object.keys(response.data.data).map((key: any) => {
                return response.data.data[key]
            })
            //console.log(bookmarks)
            setBookmarks(bookmarks)
        })
    };

    const fetchNotifications = () => {
        http.get('notifications-unread').then((response) => {
            setNotifications(response.data)
        })
        http.get('get-custom-notifications').then((response) => {
            //console.log(response.data)
            setCustomNotifications(response.data.data);
        })
    };

    const deleteNotification = (id: any, custom: boolean = false) => {
        if (custom) {
            setCustomNotifications((data: any) => {
                const newList = data.filter((item: any) => {
                    return item.id !== id;
                })
                return newList;
            })
            return;
        }
        setNotifications((data: any) => {
            const newList = data.filter((item: any) => {
                return item.id !== id;
            })
            return newList;
        })
    }

    const fetchTheoryLessons = async () => {

        await http.get(`/self-study-group-lessons`).then(async (response: any) => {
            setAlltheoryLessons(response.data)

            await http.get(`/student-study-group-attendance/${user.id}`).then((resp: any) => {
                const { attendance, student_lessons, foreign_lessons } = resp.data
                //console.log(attendance, student_lessons, foreign_lessons)
                const attendedLessons = attendance.map((lesson: any) => {
                    return {
                        ...lesson.lesson,
                        ...lesson.native,
                        csdd_nr: lesson.csdd
                    }
                });

                setTheoryLessons(attendedLessons);
                setForeignLessons(() => {
                    const mergedLessons = foreign_lessons.map((lesson: any) => {
                        return {
                            ...lesson.lesson,
                            ...lesson.foreign,
                            csdd_nr: lesson.csdd,
                            paid: lesson.paid
                        }
                    })
                    //console.log(mergedLessons)
                    return mergedLessons.reverse();
                });
                setAllSignedLessons(() => {
                    const sortedLessons = student_lessons.sort((a: any, b: any) => {
                        return Number(moment(a.lesson_date).format("YYYYMMDD")) - Number(moment(b.lesson_date).format("YYYYMMDD"))
                    })
                    return (sortedLessons)
                })
            })
        })
    }

    const fetchStudentModules = async () => {
        await http.get(`/student-modules/${user.id}`).then((response: any) => {
            //console.log("Student modules: ", response.data.data)
            setStudentModules(response.data.data);
        })
    };

    const fetchModules = async () => {
        await http.get(`/learning-modules-list/${user.category.id}`).then((response: any) => {
            //console.log("Modules list: ", response.data)
            setModules(response.data)
        })
    };

    const fetchPassedModules = async () => {
        await http.get(`/passed-modules/${user.id}`).then((response: any) => {
            //console.log("passed modules: ", response.data.data)
            setPassedModules(response.data.data)
        })
    };

    // const fetchTheoryLessons = async () => {
    //     await http.get(`/student-study-group-attendance/${self.id}`).then((resp: any) => {
    //         console.log(resp)
    //         const { attendance, student_lessons, foreign_lessons } = resp.data
    //         console.log(attendance)
    //         const attendedLessons = attendance.map((lesson: any) => {
    //             return {
    //                 ...lesson.lesson,
    //                 ...lesson.native,
    //                 csdd_nr: lesson.csdd
    //             }
    //         });

    //         setTheoryLessons(attendedLessons);
    //         setForeignLessons(() => {
    //             const mergedLessons = foreign_lessons.map((lesson: any) => {
    //                 return {
    //                     ...lesson.lesson,
    //                     ...lesson.foreign,
    //                     csdd_nr: lesson.csdd,
    //                     paid: lesson.paid
    //                 }
    //             })
    //             console.log(mergedLessons)
    //             return mergedLessons.reverse();
    //         });
    //         // setAllSignedLessons(() => {
    //         //     const sortedLessons = student_lessons.sort((a: any, b: any) => {
    //         //         return Number(moment(a.lesson_date).format("YYYYMMDD")) - Number(moment(b.lesson_date).format("YYYYMMDD"))
    //         //     })
    //         //     return (sortedLessons)
    //         // })
    //     })
    // }

    const getActiveModule = () => {
        if (modules.length > 0 && passedModules.length === modules.length) return;
        // setActiveModule(modules[passedModules.length])
        let activeModule = studentModules?.find((el: any) => el?.id === modules[passedModules.length]?.id)
        if (activeModule === undefined) return undefined;
        if (activeModule?.ind > 0) {
            activeModule = {
                ...activeModule,
                available_date: studentModules[activeModule.ind - 1]?.available_date
            }
        }
        return activeModule;
    }

    useEffect(() => {
        if (!user) return;
        console.log(user)
        if (Object.keys(user).length > 0) {
            setShowInstructions(user.category.value === "B")
            fetchBookmarks();
            // fetchNotifications();
            // fetchTheoryLessons();
            fetchModules();
            fetchStudentModules();
            fetchPassedModules();
        }
    }, [user])

    useEffect(() => {
        fetchNotifications();
    }, [i18next.language]);

    return <Routes>
        <Route
            path="/"
            element={<Layout className="main-content">
                <ElearningHeader />
                {/* {
            customNotifications?.map((notific: any) => {
                return <Notification
                    key={notific.id}
                    deleteFromList={deleteNotification}
                    notification={notific}
                    refetch={() => {
                        fetchModules();
                        fetchStudentModules();
                        fetchPassedModules();
                    }}
                    refreshBalance={() => props.refetchBalance()}
                    custom
                />
            })
        } */}
                {/* {
            notifications?.map((item: any) => {
                return <Notification
                    key={item.id}
                    deleteFromList={deleteNotification}
                    notification={item}
                    refetch={() => {
                        fetchModules();
                        fetchStudentModules();
                        fetchPassedModules();
                    }}
                    refreshBalance={() => props.refetchBalance()}
                    reload
                />
            })
        } */}
                <ProgressCard
                    modules={modules}
                    passedModules={passedModules}
                    theoryLessons={theoryLessons}
                    foreignLessons={foreignLessons}
                    showInstructions={showInstructions}
                />
                {showInstructions && <Instructions />}
                {
                    bookmarks?.length > 0
                        ? <>
                            <Row justify="space-between" align="bottom">
                                <h2 style={{ marginBottom: 20 }}>{t('e_learning.my_bookmarks')}</h2>
                                <Button
                                    type="text"
                                    style={{ display: "flex", marginBottom: 19, color: "#059DC1" }}
                                    onClick={() => navigate('/e-learning/bookmarks')}
                                >
                                    <IcBookmark className="ic-bookmark-round" style={{ marginRight: 10 }} />
                                    {t('e_learning.open_all_bookmarks')}
                                </Button>
                            </Row>
                            <Card className="e-learning-card">
                                {
                                    bookmarks?.map((bookmark: any, index: any) => {
                                        return <BookmarkTitleCard
                                            user={user}
                                            bookmark={bookmark}
                                            key={index}
                                            refreshBookmarks={fetchBookmarks}
                                        />
                                    })
                                }
                            </Card>
                        </>
                        : <></>
                }
                {
                    passedModules.length === studentModules.length && studentModules.length > 0
                        ? <>
                            <Card className="e-learning-card">
                                <h3>{t("e_learning.completed.congratulations")}</h3>
                                <p>{t("e_learning.completed.you_completed_all_modules")}</p>
                                <p>{t("e_learning.completed.for_further_info")}</p>
                            </Card>
                        </>
                        : <></>
                }
                {
                    studentModules.length === passedModules.length
                        ? <></>
                        : <>
                            <h2 style={{ marginBottom: 20 }}>{t('e_learning.active_lessons_list')}</h2>
                            <ModulesList user={user} modules={() => getActiveModule()} active />
                            <div><ModulesList user={user} modules={
                                studentModules.filter((module: any, index: any) => {
                                    return index > passedModules.length;
                                })
                            }
                            />
                            </div>

                        </>
                }
                <div>
                    {
                        passedModules.length > 0
                            ? <>
                                <h2 style={{ marginBottom: 6 }}>{t('e_learning.done_lessons')}</h2>
                                <p style={{ marginBottom: 20 }}>{t('e_learning.suggestion_to_repeat_lessons')}</p>
                                <ModulesList
                                    user={user}
                                    done
                                    modules={
                                        studentModules.filter((module: any, index: any) => {
                                            return index < passedModules.length;
                                        })
                                    }
                                    doneChapters
                                />
                            </>
                            : <></>
                    }
                </div>
            </Layout>}
        />
        <Route
            path=":id/:mid/:cid"
            element={
                <LessonLayout
                    user={user}
                    refreshBookmarks={fetchBookmarks}
                    bookmarks={bookmarks}
                />
            }
        />
        <Route
            path="exam/:mid"
            element={<ExamStartPage user={user} />}
        />
        <Route
            path="questions"
            element={<MyQuestions

            />}
        />
        <Route
            path="bookmarks"
            element={
                <BookmarksView
                    user={user}
                    bookmarks={bookmarks}
                    refreshBookmarks={fetchBookmarks} />
            }
        />
    </Routes>
}


//                                         <Route path="/e-learning/bookmarks">
//                                             {
//                                                 this.state.eLearning && <BookmarksView self={this.state.self} />
//                                             }
//                                         </Route>
//                                         <Route exact path="/e-learning">
//                                             {
//                                                 this.state.eLearning && <ELearningLayout refetchBalance={() => this.fetchBalance()} self={this.state.self} />
//                                             }
//                                         </Route>