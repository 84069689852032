import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import locale from 'antd/locale/lv_LV';
import { ConfigProvider } from 'antd';
import lv from './locale/lv.json';
import en from './locale/en.json';
import ru from './locale/ru.json';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { http } from './helpers/http';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const getLangs = async () => {
    await http.get('get-translations').then((response: any) => {
        const { data } = response.data;
        i18n.addResourceBundle('en', 'translation', data[3].translation, true, true)
        i18n.addResourceBundle('lv', 'translation', data[1].translation, true, true)
        i18n.addResourceBundle('ru', 'translation', data[2].translation, true, true)
    })
    i18n.reloadResources();
}


i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            lv: {
                translation: lv
            },
            ru: {
                translation: ru
            }
        },
        lng: "lv",
        fallbackLng: "lv",
        interpolation: {
            escapeValue: false
        }
    })


getLangs();

root.render(
    <BrowserRouter>
        <React.StrictMode>
            <ConfigProvider locale={locale}>
                <App />
            </ConfigProvider>
        </React.StrictMode>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
