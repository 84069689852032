import { Button, Card, Col, Row } from "antd";
import React, { useEffect } from "react";
// import { ReactComponent as IcChapterDone } from '../../img/ic-cc-5.svg';
// import { ReactComponent as IcChapterUpcoming } from '../../img/ic-cc-3.svg';
// import { ReactComponent as IcChapterActive } from '../../img/ic-cc-4.svg';
// import { ReactComponent as IcExam } from '../../img/ic-cc-2.svg';
import chapterDone from '../../img/ic-cc-5.svg';
import chapterUpcoming from '../../img/ic-cc-3.svg';
import chapterActive from '../../img/ic-cc-4.svg';
import chapterExam from '../../img/ic-cc-2.svg';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

export const ChapterTitleCard = (props: any) => {
    const { t } = useTranslation();
    const { ind, chapter, categoryId, exam, module } = props;
    const disabled = !props.next && !props.active && !props.done;
    const navigate = useNavigate();

    useEffect(() => {
        //console.log(props.next)
    }, []);

    const goTo = (to: "exam" | "module" | "disabled") => {
        if (to === "exam") {
            navigate(`/e-learning/exam/${props.moduleID}`);
        }
        if (to === "module") {
            navigate(`/e-learning/${categoryId}/${module.hash_id}/${chapter.hash_id}`);
        }

    }

    return <Card
        onClick={() => goTo(disabled ? "disabled" : exam ? "exam" : "module")}
        hoverable={!disabled}
        style={{ marginBottom: 5, opacity: disabled ? .3 : 1 }}
    >
        <Row>
            {
                props.done
                    // ? <IcChapterDone style={{ marginRight: 10 }} />
                    ? <img src={chapterDone} style={{ marginRight: 10 }}/>
                    : props.active
                        ? <img src={chapterActive} style={{ marginRight: 10 }}/>
                        : exam
                            ? <img src={chapterExam} style={{ marginRight: 10 }}/>
                            : <img src={chapterUpcoming} style={{ marginRight: 10 }}/>
            }
            <b>{exam ? `${t('e_learning.final_test')}.` : chapter[`title_${i18n.language}`]}</b><br />
            <Col span={24}>
                <span>
                    {
                        exam
                            ? <></>
                            : <>
                                <small>{chapter[`description_${i18n.language}`]}</small>
                                {
                                    props.active
                                        ? <Button danger type="text">
                                            {t('e_learning.lesson_attachments')}
                                        </Button>
                                        : props.done
                                            ? <></>
                                            : <></>
                                }
                            </>
                    }
                </span>
            </Col>
        </Row>
    </Card>
}