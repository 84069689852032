import { Row, Input, Col, Button, Radio, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { ReactComponent as IconClock } from "../../img/ic-time-left.svg";
import { CountDownTimer } from "./common/CountDownTimer";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
//@ts-ignore
import { useBeforeunload } from "react-beforeunload";
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

export const ExamQuestion = (props: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { question, exam, review, mobile, ind } = props;
    const [selectedAnswer, setAnswer] = useState(undefined as any);
    const [loading, setLoading] = useState(false);

    const actionHandler = async () => {
        if (loading) return;
        setLoading(true);
        await http.post(`/set-module-exam-answer/${exam.id}`, { answer_id: selectedAnswer }).then((response: any) => {
            //console.log(response)
            const result = {
                correct: response.data.data.correct,
                answer_id: response.data.data.e_module_exam_question_answer_id,
                correct_id: response.data.correct.id
            }
            props.nextQuestion(result);
            setLoading(false);
        }).catch((err: AxiosError) => {
            console.log(err.response?.data?.message.includes("Hackerman"));
            if (err.response?.data?.message.includes("Hackerman")) {
                hackermanMessage();
            }
            setLoading(false)
        });
        setAnswer(undefined);
    }

    const hackermanMessage = () => {
        Modal.confirm({
            title: "Kļūda!",
            content: <div>
                <b>Iesniegto atbilžu daudzums pārsniedz jautājumu skaitu!<br />Lūgums sazināties ar administrāciju!<br/> Jūs tiksiet pārvirzīts uz Online apmācības lapu.</b>

            </div>,
            onOk: () => {
                navigate("/e-learning")
            },
            onCancel: () => {
                navigate("/e-learning")
            }
        })
    }

    useBeforeunload((event: any) => {
        event.preventDefault();
    });

    useEffect(() => {
    }, []);

    return <div
        // className={`question-card ${timeIsUp ? 'time-is-up' : ''}`}
        className="exam-question-card"
    >
        {
            // timeIsUp
            false
                ? <Row>
                    <Col span={24}>
                        {/* <Row justify="center" style={{ marginBottom: 30 }}>
                                <div className="icon-round-wrap">
                                    <IconClock />
                                </div>
                            </Row>
                            <p style={{ textAlign: "center" }}>
                                Atlikušais laiks: <b style={{ color: "#CC3326" }}>00:00</b>
                            </p>
                            <h3>Jūsu kontroljautājuma atbildes laiks ir iztecējis.</h3>
                            <button
                                className="btn-submit"
                                onClick={actionHandler}
                            >
                                Sākt no sākuma
                            </button> */}
                    </Col>
                </Row>
                : <Row gutter={24} style={{ height: "100%" }}>
                    <Col span={24} lg={15} style={{ borderRight: mobile ? "" : "1px solid #E0E6EC", paddingTop: 48, paddingBottom: 48 }}>
                        <b className="question-big-text">{ind + 1}. {question?.[`question_${i18n.language}`]}</b>
                        {
                            question?.img !== null
                                ? <div className="img-wrap question-img">
                                    <img src={process.env.REACT_APP_API_URL + question?.public_image} alt="" />
                                </div>
                                : <></>
                        }
                        {
                            question?.[`description_${i18n.language}`] !== null
                                ? <div className="description-wrap">
                                    <b>{question?.[`description_${i18n.language}`]}</b>
                                </div>
                                : <></>
                        }
                    </Col>
                    <Col
                        span={24}
                        lg={9}
                        style={{
                            paddingTop: 48,
                            paddingBottom: 48,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}
                    >
                        <div>
                            {/* <Row justify="space-between">
                                <p>Kontroljautajums</p>
                                <p>Atlikušais laiks:&nbsp;
                                    <CountDownTimer
                                        seconds={question.answer_time_limit}
                                        stopTimer={stopTimer}
                                        setTimeIsUp={setTimeIsUp}
                                    />
                                </p>
                            </Row> */}

                            <Radio.Group
                                value={review ? question.answer_id : selectedAnswer}
                                style={{ width: "100%" }}
                                onChange={(e: any) => {
                                    if (review) return;
                                    //console.log(e.target.value)
                                    setAnswer(e.target.value)
                                }}
                            >
                                {
                                    question?.answers.map((answer: any, index: any) => {
                                        return <div
                                            key={index}
                                            className={`answer ${answer.id === selectedAnswer
                                                // ? selectedAnswer === undefined
                                                //     ? "selected"
                                                //     : "correct"
                                                //         ? "correct"
                                                //         : "incorrect"
                                                ? "selected"
                                                : ""
                                                } ${review
                                                    ? answer.id === question.correct_id
                                                        ? "correct"
                                                        : "incorrect"
                                                    : ""
                                                }`}
                                        >
                                            <Radio value={answer.id} />
                                            <div className="answer-col">
                                                {
                                                    answer?.img !== null
                                                        ? <div className="img-wrap">
                                                            <img src={process.env.REACT_APP_API_URL + answer?.public_image} alt="" />
                                                        </div>
                                                        : <></>
                                                }
                                                <p>{answer?.[`answer_${i18n.language}`]}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </Radio.Group>
                            {/* {
                                correct === true
                                    ? <b className="warning green">Iesniegtā atbilde ir pareiza!</b>
                                    : correct === false
                                        ? <b className="warning red">Iesniegtā atbilde nav pareiza!</b>
                                        : <></>
                            } */}
                        </div>
                        <Button
                            className={`btn-submit ${selectedAnswer ? '' : 'disabled'}`}
                            onClick={actionHandler}
                            loading={loading}
                        >
                            {t('e_learning.next_question')}
                            {/* {
                                    correct === true
                                        ? "Turpināt nodarbību"
                                        : correct === false
                                            ? "Sākt no sākuma"
                                            : "Iesniegt atbildi"
                                } */}
                        </Button>
                    </Col>
                </Row>
        }
    </div>
}